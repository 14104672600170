/* ===================
auth css 
====================== */

.login-form {
    padding: 30px 0 50px 0;
    border-radius: 5px;
    background: #fff;
    /*box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);*/
}

.login-form .login-header {
    text-align: center;
    margin-bottom: 50px;
}

.login-form .login-header img {
    height: 80px;
    margin-bottom: 10px;
}

.login-form .login-header h3 {
    color: #1d42d9;
    margin-bottom: 5px;
    font-weight: 800;
}

.login-form .login-header p {
    font-size: 20px;
}

.login-form .login-footer {
    margin-top: 40px;
}

.login-form .login-footer p {
    text-align: center;
}

.login-form .login-footer a {
    color: #00AF92;
    transition: .5s;
}

.login-form .login-footer a:hover {
    color: #202029;
}

.login-form .form-group {
    margin-bottom: 20px;
}

.login-form label {
    color: #000;
    margin-bottom: 12px;
}

.login-form p {
    margin-top: 12px;
    font-size: 15px;
    color: #757F95;
}

.login-form .form-group .form-control {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 12px 18px;
    box-shadow: none;
    transition: .5s;
}

.login-form .form-group .form-control:focus {
    border-color: #00AF92;
}

.login-form .form-check-input {
    box-shadow: none;
}

.login-form .form-check-input:checked {
    background-color: #00AF92;
    border-color: #00AF92;
}

.login-form .forgot-pass {
    color: #00AF92;
    transition: .5s;
}

.login-form .forgot-pass:hover {
    color: #202029;
}

.login-form .login-btn {
    width: 100%;
    text-align: center;
    background: #00AF92;
    color: #fff;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
    transition: .5s;
}

.login-form .login-btn i {
    margin-right: 5px;
}

.login-form .login-btn:hover {
    background: #202029;
    color: #fff;
}

.login-form .form-check-input {
    /*margin-top: 6.3px;*/
}

.login-form .form-check-label a {
    color: #00AF92;
    transition: .5s;
}

.login-form .form-check-label a:hover {
    color: #202029;
}

.other-login-signup {
    text-align: center;
    overflow: hidden;
}

.other-login-signup .or-login-signup {
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    z-index: 1;
}

.other-login-signup .or-login-signup:before {
    display: inline-block;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #e5e9f2;
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
}

.other-login-signup .or-login-signup:after {
    display: inline-block;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #e5e9f2;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
}

.social-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.social-login a {
    padding: 8px 18px;
    border-radius: 7px;
    margin: 0 4px;
    transition: .5s;
}

.social-login a i {
    margin-right: 6px;
}

.social-login .btn-f {
    background-color: #4064ac;
    border-color: #4064ac;
    color: #fff;
}

.social-login .btn-g {
    background-color: #dd4b39;
    border-color: #dd4b39;
    color: #fff;
}

.social-login .btn-t {
    background-color: #00a7e6;
    border-color: #00a7e6;
    color: #fff;
}

.social-login a:hover {
    opacity: .8;
}

@media only screen and (max-width: 991px) {
    .login-form {
        padding: 40px 20px;
    }
}

.btn-outline-code {
    color: #00af92;
    border-color: #00af92;
}

.btn-outline-code:hover {
    background: #00af92;
    color: #fff;
}

.btn-outline-no {
    color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-no:hover {
    color: #6c757d;
    border-color: #6c757d;
}

.el-input .el-input__inner {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 12px 18px;
    box-shadow: none;
    transition: .5s;
    height: 50px;
    font-size: 16px;
    font-family: Arial;
    color: #000;
}

.el-input .el-input__inner:focus {
    border-color: #00AF92;
}